
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'
import { useModals } from '@/compositions/modals'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'

export default defineComponent({
  name: 'ContactsListsDetailsFilter',
  components: {
    TopFilterButton,
    TopFilter,
    TmDropdownDownload,
  },
  props: {
    selected: {
      type: Array as PropType<ContactListsDetails[]>,
      required: true,
    },
  },
  setup() {
    const { openModal } = useModals()
    const { openDeleteContactsModal, openMoveContactsModal } = useContactsOpenModal()
    const { openListsShareModal, openEditListsModal, openDeleteListModal } = useContactsListsOpenModal()

    const openContactsListsAddModal = () => openModal('grayTileButtons', {
      modalTitle: 'Add contacts',
      items: [
        {
          icon: 'tmi-person-add',
          label: 'Create a new contact',
          description: 'Add contacts one by one',
          onClick: () => openModal('contact'),
        },
        {
          icon: 'tmi-people',
          label: 'Add from existing contacts',
          description: 'Copy contacts from other Lists',
          onClick: () => openModal('addExistingContacts'),
        },
      ],
    })

    return {
      openModal,
      openDeleteContactsModal,
      openListsShareModal,
      openDeleteListModal,
      openEditListsModal,
      openContactsListsAddModal,
      openMoveContactsModal,
    }
  },
})
