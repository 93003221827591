
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import FilterRadio from '@/components/shared/filters/radio/FilterRadio.vue'
import FilterFooter from '@/components/shared/filters/FilterFooter.vue'
import { filterInputLib } from '@/definitions/shared/filters/filterComponentsLib/data'
import type { FilterRadioOption } from '@/definitions/shared/filters/types'

export default defineComponent({
  name: 'DropdownFilterRadio',
  components: {
    FilterFooter,
    FilterRadio,
    FilterButton,
    TmDropdown,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Array, String, Object],
      default: () => [],
    },
    name: {
      type: String,
    },
    icon: {
      type: String,
    },
    allText: {
      type: String,
    },
    btnSize: {
      type: String as PropType<'default' | 'small' | 'large' | 'xLarge'>,
      default: 'large',
    },
    options: {
      type: Array,
      default: () => [],
    },
    radioOptions: {
      type: Array as PropType<FilterRadioOption[]>,
      default: () => [],
    },
    radioValue: {
      type: String,
    },
    disable: {
      type: Boolean,
    },
    buttonClass: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    component: {
      type: String,
    },
  },
  emits: ['update:radio-value', 'update:model-value'],
  setup(props, { emit }) {
    const localModelValue = ref(props.modelValue)
    const localRadioValue = ref<string | null>(props.radioValue || props.radioOptions[0]?.radioValue)
    const localRadioField = computed(
      () => props.radioOptions?.find(item => item.radioValue === localRadioValue.value)?.field || ''
    )
    const savedRadioValue = computed(() => props.radioOptions?.find(item => item?.radioValue === props.radioValue))
    const savedRadioField = computed(
      () => savedRadioValue.value?.field || ''
    )
    const optionItem = computed(
      () => localRadioField.value ? filterInputLib[localRadioField.value]?.optionItem : null
    )
    const selectedItem = computed(
      () => localRadioField.value ? filterInputLib[localRadioField.value]?.selectedItem : null
    )
    const btnPreLabel = computed(() => savedRadioValue.value?.btnPreLabel)
    const valueStr = computed(() => {
      if (props.modelValue) {
        const isTextBtnFunc = filterInputLib[savedRadioField.value]?.textBtn
        if (isTextBtnFunc) {
          return isTextBtnFunc(props.modelValue)
        } else if (props.radioValue && !savedRadioValue.value?.field) {
          return props.modelValue
        } else if (props.modelValue.length) {
          if (typeof props.modelValue === 'string') {
            return props.modelValue
          } else if (Array.isArray(props.modelValue)) {
            const fieldName = savedRadioValue.value
              ? filterInputLib[savedRadioValue.value.field]?.optionLabel
              : false

            return fieldName && Array.isArray(props.modelValue) ? props.modelValue[0][fieldName] : props.modelValue[0]
          }
        }
      }
      return ''
    })
    const valuePlus = computed(() => {
      if (Array.isArray(props.modelValue)) {
        return props.modelValue.length > 1 ? props.modelValue.length - 1 : null
      }
      return null
    })
    const isLocalModelValueValid = computed(() => {
      if (localModelValue.value) {
        if (typeof localModelValue.value === 'object' && Object.keys(localModelValue.value).length) return true
        else if (localModelValue.value.length) return true
      }
      return false
    })

    const getImage = (item: any, opt: string): string => item.image ? item.image(opt) : ''

    const update = (close: () => void): void => {
      if (isLocalModelValueValid.value) {
        emit('update:model-value', localModelValue.value)
        emit('update:radio-value', localRadioValue.value)
      } else {
        clear()
      }
      close()
    }

    const updateRadioValue = (val: any) => {
      localRadioValue.value = val.value
      localModelValue.value = val.modelValueType
    }

    const clear = () => {
      emit('update:model-value', null)
      emit('update:radio-value', null)
      localRadioValue.value = props.radioOptions[0].radioValue
      localModelValue.value = []
    }

    const closeFilter = (func?: () => void) => {
      localModelValue.value = props.modelValue
      localRadioValue.value = props.radioValue || props.radioOptions[0]?.radioValue
      if (func) { func() }
    }

    return {
      filterInputLib,
      btnPreLabel,
      valueStr,
      valuePlus,
      localModelValue,
      localRadioValue,
      update,
      clear,
      updateRadioValue,
      savedRadioValue,
      optionItem,
      selectedItem,
      getImage,
      localRadioField,
      closeFilter,
    }
  },
})
