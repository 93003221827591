
import type { PropType } from 'vue'
import { defineComponent, reactive, watch } from 'vue'

type PropValue = {
  from: number;
  to: number;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<PropValue>,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = reactive({
      from: props.modelValue?.from || null,
      to: props.modelValue?.to || null,
    })

    watch(value, () => {
      emit('update:modelValue', value.from ? value : null)
    })
    return {
      value,
    }
  },
})
