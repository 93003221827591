
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { AvatarColorVariant } from '@/definitions/shared/types'
import { avatarColorVariant } from '@/definitions/_general/_data/avatarColorVariant'
import { randomIntFromInterval } from '@/services/utils'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    image: {
      type: String,
    },
    icon: {
      type: String,
    },
    avatarColor: {
      type: String as PropType<AvatarColorVariant>,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    customIconSize: {
      type: String,
      default: '16px',
    },
    inline: {
      type: Boolean,
    },
  },
  setup(props) {
    const totalAvatarColor = computed(() => {
      const colorsExceptWhite = avatarColorVariant.filter(item => item !== 'white')
      return props.avatarColor ? props.avatarColor : colorsExceptWhite[randomIntFromInterval(0, colorsExceptWhite.length)]
    })
    return {
      totalAvatarColor,
    }
  },
})
