
import { defineComponent } from 'vue'
import TmAutoSizePanelItemDropdown from '@/components/shared/autoSize/panel/TmAutoSizePanelItemDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useModals } from '@/compositions/modals'
import type { ButtonSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmDropdownDownload',
  components: {
    TopFilterButton,
    TmDropdownItem,
    TmAutoSizePanelItemDropdown,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
      default: 'Download',
    },
    iconOnly: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const { openModal } = useModals()
    const openDownloadModal = () => {
      openModal('downloadModal')
    }
    const openDownloadToEmailModal = () => {
      openModal('singleInput', {
        modalTitle: 'Send the download link to an email',
        description: 'Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.',
        label: 'Email address',
        placeholder: 'Enter email address',
        btnText: 'Send',
      })
    }

    return {
      isSmMax,
      openDownloadModal,
      openDownloadToEmailModal,
    }
  },
})
