import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4269df84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_button = _resolveComponent("filter-button")!
  const _component_filter_radio = _resolveComponent("filter-radio")!
  const _component_filter_footer = _resolveComponent("filter-footer")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    "filter-dropdown": "",
    onHide: _ctx.closeFilter
  }, {
    default: _withCtx(({ internalValue }) => [
      _renderSlot(_ctx.$slots, "default", { value: _ctx.valueStr }, () => [
        _createVNode(_component_filter_button, {
          class: "mr-2",
          removable: _ctx.modelValue && _ctx.modelValue.length && _ctx.modelValue !== _ctx.radioOptions[0].modelValue && _ctx.removable,
          "btn-pre-label": _ctx.btnPreLabel,
          value: _ctx.valueStr,
          name: _ctx.name,
          icon: _ctx.icon,
          "all-text": _ctx.allText,
          "btn-size": _ctx.btnSize,
          "value-plus": _ctx.valuePlus,
          "is-open": internalValue,
          disable: _ctx.disable,
          "button-class": _ctx.buttonClass,
          onClear: _ctx.clear
        }, null, 8, ["removable", "btn-pre-label", "value", "name", "icon", "all-text", "btn-size", "value-plus", "is-open", "disable", "button-class", "onClear"])
      ], true)
    ]),
    menu: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_filter_radio, {
          "model-value": _ctx.localModelValue,
          "radio-value": _ctx.localRadioValue,
          options: _ctx.options,
          "radio-options": _ctx.radioOptions,
          "selected-item-icon": _ctx.optionItem?.icon,
          onSubmit: close,
          "onUpdate:radioValue": _ctx.updateRadioValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.localModelValue = $event))
        }, {
          option: _withCtx(({ opt }) => [
            _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ opt })), () => [
              (_ctx.optionItem?.component)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.optionItem?.component), {
                    key: 0,
                    icon: _ctx.optionItem?.icon,
                    "avatar-color": _ctx.optionItem?.avatarColor || opt.avatarColor,
                    title: _ctx.optionItem?.title(opt),
                    subtitle: _ctx.optionItem?.subtitle(opt),
                    "custom-icon-size": _ctx.optionItem?.customIconSize,
                    image: _ctx.getImage(_ctx.optionItem, opt)
                  }, null, 8, ["icon", "avatar-color", "title", "subtitle", "custom-icon-size", "image"]))
                : _createCommentVNode("", true)
            ], true)
          ]),
          "selected-item": _withCtx(({ opt, index, removeItem }) => [
            _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ opt, index, removeItem })), () => [
              (_ctx.selectedItem?.component)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedItem?.component), {
                    key: 0,
                    name: _ctx.selectedItem?.name(opt),
                    avatar: _ctx.getImage(_ctx.selectedItem, opt),
                    "avatar-color": opt?.avatarColor || opt?.color,
                    clearable: "",
                    onRemove: ($event: any) => (removeItem(index))
                  }, null, 40, ["name", "avatar", "avatar-color", "onRemove"]))
                : _createCommentVNode("", true)
            ], true)
          ]),
          _: 2
        }, 1032, ["model-value", "radio-value", "options", "radio-options", "selected-item-icon", "onSubmit", "onUpdate:radioValue"]),
        _createVNode(_component_filter_footer, {
          class: "mt-4",
          onApply: ($event: any) => (_ctx.update(close)),
          onCancel: ($event: any) => (_ctx.closeFilter(close))
        }, null, 8, ["onApply", "onCancel"])
      ])
    ]),
    _: 3
  }, 8, ["onHide"]))
}