import type { FiltersListType } from '@/definitions/shared/filters/types'
import {
  assignee,
  countries,
  lists,
  priority,
  organizations,
  segments,
  tags,
  dateCreated,
  createdBy,
  domains,
  addContactFilters,
  addOrganizationFilters,
  contactStatusesFilter,
  organizationTypesFilter,
  activities,
  activitiesChats,
  activitiesEmails,
  activitiesCalls,
  ticketStatusesFilter,
  files,
  requesters,
  addTicketFilters,
  dueDate,
  checkboxOrganizations,
  amount,
  reportsChannels,
  reportsAccounts,
  reportsWidgets,
  reportsNumbers,
  boards,
  pipelines,
  inboxFilter,
  campaignStatusesFilter,
  campaignTypesFilter,
  campaignSentMessagesStatusFilter
} from '@/definitions/shared/filters/filtersLib/data'

export const contactsFilter: FiltersListType = {
  contactStatusesFilter,
  tags,
  countries,
  assignee: { ...assignee },
  dateCreated: { ...dateCreated },
  organizations,
  lists,
  segments,
  followers: { ...assignee },
  lastUpdated: { ...dateCreated },
  createdBy,
  addFilters: [...addContactFilters],
}

export const contactsDetailsFilter: FiltersListType = {
  contactStatusesFilter,
  tags,
  countries,
  assignee: { ...assignee },
  dateCreated: { ...dateCreated },
  organizations,
  lists,
  segments,
  followers: { ...assignee },
  lastUpdated: { ...dateCreated },
  addFilters: [...addContactFilters],
}

export const listsFilters: FiltersListType = {
  tags,
  contacts: { ...assignee },
  lastUpdated: { ...dateCreated },
  dateCreated: { ...dateCreated },
  createdBy,
  lastUpdatedBy: { ...createdBy },
}

export const segmentsFilters: FiltersListType = {
  tags,
  contacts: { ...assignee },
  lastUpdated: { ...dateCreated },
  dateCreated: { ...dateCreated },
  createdBy,
  lastUpdatedBy: { ...createdBy },
}

export const organizationsFilters: FiltersListType = {
  organizationTypesFilter,
  tags,
  owner: { ...assignee },
  countries,
  createdBy,
  // size,
  // industry,
  primaryContact: { ...assignee },
  dateCreated: { ...dateCreated },
  lastUpdated: { ...dateCreated },
  domains,
  addFilters: [...addOrganizationFilters],
}

export const activitiesFilter: FiltersListType = {
  activities,
  activitiesChats,
  activitiesEmails,
  activitiesCalls,
  files,
  assignee: { ...assignee },
  dateCreated: { ...dateCreated },
}
export const segmentsDetailsFilters: FiltersListType[] = [
  {
    countries,
    organizations,
    addFilters: [...addContactFilters],
  },
  {
    countries,
    addFilters: [...addContactFilters],
  },
]
export const segmentsDetailsFiltersDefault = {
  countries,
  addFilters: [...addContactFilters],
}

export const ticketsFilter: FiltersListType = {
  priority,
  requesters,
  dateCreated: dateCreated,
  ticketStatusesFilter,
  lastUpdated: dateCreated,
  assignee: assignee,
  addFilters: [...addTicketFilters],
}

export const campaignsSentFilters: FiltersListType = {
  sendDate: { ...dateCreated },
  tags,
  createdBy,
}

export const campaignsDraftsFilters: FiltersListType = {
  lastUpdated: { ...dateCreated },
  tags,
  createdBy,
}

export const campaignsScheduledFilters: FiltersListType = {
  campaignStatusesFilter,
  campaignTypesFilter,
  nextSendDate: { ...dateCreated },
  tags,
  createdBy,
}

export const dealsFilter: FiltersListType = {
  dueDate: dueDate,
  organization: checkboxOrganizations,
  contact: { ...assignee },
  amount: amount,
  dealOwner: { ...assignee },
}

export const reportsFilter: FiltersListType = {
  reportsAccounts,
  reportsChannels,
  reportsNumbers,
  reportsWidgets,
  inboxes: { ...inboxFilter },
  boards,
  pipelines,
  dateCreated: { ...dateCreated },
}

export const campaignsSentMessages: FiltersListType = {
  campaignSentMessagesStatusFilter,
  countries,
}

export const campaignsResponses: FiltersListType = {
  date: { ...dateCreated },
  countries,
}
