import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, { selected: _ctx.selected }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Send SMS",
        icon: "email"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Add contacts",
        icon: "add",
        onClick: _ctx.openContactsListsAddModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_download)
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Send SMS",
        icon: "email"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Move/copy",
        icon: "tmi-duplicate",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMoveContactsModal(_ctx.selected)))
      }),
      _createVNode(_component_top_filter_button, {
        text: "Remove from list",
        icon: "close",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openRemoveContactsFromListModal(_ctx.selected)))
      }),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDeleteContactsModal(_ctx.selected)))
      })
    ]),
    _: 1
  }, 8, ["selected"]))
}