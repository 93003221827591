import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-535c26d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "w120px mr-2" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.date.fromDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date.fromDate) = $event)),
        type: "number",
        placeholder: "2",
        class: "w100pr"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.date.dateType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date.dateType) = $event)),
        type: "select",
        options: _ctx.dateOptions,
        class: "w100pr",
        clearable: false
      }, null, 8, ["modelValue", "options"])
    ])
  ]))
}