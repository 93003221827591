<template>
  <input
    type="file"
    class="file-attach"
  >
</template>

<style lang="scss" scoped>
.file-attach {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  min-width: 0;
  width: 100%;
  font-size: 0;
}
</style>
