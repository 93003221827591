
import { defineComponent, computed, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ContactsListsDetailsTable from '@/components/pages/contacts/lists/details/ContactsListsDetailsTable.vue'
import ContactsListsDetailsFilter from '@/components/pages/contacts/lists/details/ContactsListsDetailsFilter.vue'
import ContactsListsDetailsEmpty from '@/components/pages/contacts/lists/details/ContactsListsDetailsEmpty.vue'
import ContactsListsDetailsActions from '@/components/pages/contacts/lists/details/ContactsListsDetailsActions.vue'
import ContactsListsDetailsSharedFilter from '@/components/pages/contacts/lists/details/ContactsListsDetailsSharedFilter.vue'
import { getTableData } from '@/services/tableService'
import type { BreadcrumbsLink, TableHeaders } from '@/definitions/shared/types'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'
import { useModes } from '@/compositions/modes'
import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'
import { useRoute } from 'vue-router'
import { contactsDetailsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'

export default defineComponent({
  name: 'ContactsListsDetails',
  components: {
    PageContent,
    DetailsHero,
    ContactsListsDetailsTable,
    ContactsListsDetailsFilter,
    ContactsListsDetailsEmpty,
    ContactsListsDetailsSharedFilter,
    ContactsListsDetailsActions,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const title = ref('New trials 13-Jan-22')
    const route = useRoute()
    const { openEditListsModal } = useContactsListsOpenModal()

    const breadcrumbsLink: BreadcrumbsLink[] = [
      {
        label: 'Contacts',
        name: 'base.contacts',
      },
      {
        label: 'Lists',
        name: 'base.contacts.lists',
      },
      {
        label: 'New trials 13-Jan-22',
      },
    ]

    const search = ref('')
    const { filters, isDefault, reset } = useFilters(contactsDetailsFilter)
    const selected = ref<ContactListsDetails[]>([])
    const isShared = computed(() => route.params.shared === 'true')

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone' },
      { text: 'Email', value: 'email' },
      { text: 'Organization', value: 'organization-slot' },
      { text: 'Contact type', value: 'type-slot' },
    ])
    const tableItems = ref<ContactListsDetails[]>(getTableData('contactsListsDetails'))
    const filteredTableItems = computed<ContactListsDetails[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isShared,
      route,
      search,
      title,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
      openEditListsModal,
      isEmptyMode,
      breadcrumbsLink,
      filters,
      isDefault,
      reset,
    }
  },
})
