import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contact-dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_auto_size_panel_item_dropdown = _resolveComponent("tm-auto-size-panel-item-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_panel_item_dropdown, {
    text: _ctx.label,
    icon: "add",
    "icon-size": "small",
    disabled: _ctx.disabled,
    size: "large"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "menu", {}, () => [
          _createVNode(_component_tm_dropdown_item, {
            icon: "tmi-person-add",
            label: "Create new contact",
            onClick: _ctx.openModalNewContact
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            icon: "supervisor_account",
            label: "Add from existing contacts",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('addExistingContacts')))
          }),
          _createVNode(_component_tm_dropdown_item, {
            to: { name: 'base.contacts.importFlow' },
            icon: "tmi-upload",
            label: "Import contacts"
          }, null, 8, ["to"]),
          _renderSlot(_ctx.$slots, "default-menu-append")
        ])
      ])
    ]),
    _: 3
  }, 8, ["text", "disabled"]))
}