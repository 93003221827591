import _ from 'lodash'

export type Filter = {
  value: string | string[];
  options?: any[];
  radioValue?: string;
}

type FiltersDefault = {
  value: boolean;
  changesCount: number;
}

type FilterWrap = { [name: string]: Filter }

export const createDefaultFilters = (filters: FilterWrap): FilterWrap => {
  const result: FilterWrap = {}
  Object.keys(filters).forEach((name) => {
    result[name] = { ...filters[name] }
  })
  return result
}

export const isFiltersDefault = (filters: FilterWrap, defaultFilters: FilterWrap): FiltersDefault => {
  const fields: string[] = []
  Object.keys(filters).forEach((name) => {
    if (filters[name].radioValue) {
      if (
        !_.isEqual(filters[name].radioValue, defaultFilters[name].radioValue) ||
        !_.isEqual(filters[name].value, defaultFilters[name].value)
      ) {
        fields.push(name)
      }
    } else if (!_.isEqual(filters[name].value, defaultFilters[name].value)) {
      fields.push(name)
    }
  })
  return { value: !fields.length, changesCount: fields.length }
}

export const resetFilters = (filters: FilterWrap, defaultFilters: FilterWrap): void => {
  Object.keys(filters).forEach((name) => {
    filters[name].value = defaultFilters[name].value
    filters[name].radioValue = defaultFilters[name]?.radioValue
  })
}
