import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_filter_checkbox = _resolveComponent("filter-checkbox")!

  return (_openBlock(), _createBlock(_component_filter_checkbox, {
    "option-key": "name",
    "option-label": "name",
    "search-placeholder": "Find statuses",
    searchable: !_ctx.hideSearch
  }, {
    option: _withCtx(({option}) => [
      _createVNode(_component_tm_status, {
        name: option.name,
        color: option.color,
        outline: _ctx.outlineOptions || option.outline
      }, null, 8, ["name", "color", "outline"])
    ]),
    _: 1
  }, 8, ["searchable"]))
}