
import type { PropType } from 'vue'
import { defineComponent, reactive, watch } from 'vue'

const dateOptions = [
  'hours',
  'days',
  'weeks',
]

type PropDate = {
  fromDate: number;
  toDate: number;
  dateType: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<PropDate>,
    },
    name: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const date = reactive({
      fromDate: props.modelValue?.fromDate || null,
      toDate: props.modelValue?.toDate || null,
      dateType: props.modelValue?.dateType || dateOptions[0],
    })

    watch(date, () => {
      emit('update:modelValue', date.fromDate || date.toDate ? date : null)
    })
    return {
      dateOptions,
      date,
    }
  },
})
