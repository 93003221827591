
import { defineComponent } from 'vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'

export default defineComponent({
  components: {
    TmAutoSizePanelItemButton,
    TmDropdownDownload,
  },
  props: {
    listName: {
      type: String,
    },
  },
  setup() {
    const { openEditListsModal, openDeleteListModal, openDuplicateListsModal } = useContactsListsOpenModal()

    return {
      openEditListsModal,
      openDeleteListModal,
      openDuplicateListsModal,
    }
  },
})
