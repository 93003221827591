import FilterAutocomplete from '@/components/shared/filters/FilterAutocomplete.vue'
import FieldTextarea from '@/components/shared/field/FieldTextarea.vue'
import FieldEditableBadges from '@/components/shared/field/FieldEditableBadges.vue'
import FieldMultipleCountries from '@/components/shared/field/FieldMultipleCountries.vue'
import FilterOptionWithAvatar from '@/components/shared/filters/radio/FilterOptionWithAvatar.vue'
import Datepickers from '@/components/shared/filters/date/Datepickers.vue'
import NumbersAndTypeOfDate from '@/components/shared/filters/date/NumbersAndTypeOfDate.vue'
import NumberAndTypeOfDate from '@/components/shared/filters/date/NumberAndTypeOfDate.vue'
import TmRecipient from '@/components/shared/TmRecipient.vue'
import { formatDate } from '@/services/dateTimeService'
import type { FilterInputItem } from '@/definitions/shared/filters/types'
import FilterAmountField from '@/components/shared/filters/amount/FilterAmountField.vue'

export const filterInputLib: Record<string, FilterInputItem> = {
  tags: {
    component: FilterAutocomplete,
    placeholder: 'Start typing tag name',
  },
  textarea: {
    component: FieldTextarea,
    placeholder: 'Enter text',
  },
  subjectEditableBadges: {
    component: FieldEditableBadges,
    optionLabel: 'text',
    placeholder: 'Enter text',
  },
  organizations: {
    component: FilterAutocomplete,
    optionLabel: 'name',
    selectedItemNotBadge: true,
    placeholder: 'Start typing organization name',
    placeholderLg: true,
    optionItem: {
      component: FilterOptionWithAvatar,
      image: (val: any) => val?.image ? require(`@/assets/${val?.image}`) : '',
      title: (val: any) => val?.name,
      subtitle: (val: any) => val?.domain,
    },
    selectedItem: {
      component: TmRecipient,
      name: (val: any) => val?.name,
      image: (val: any) => val?.image ? require(`@/assets/${val?.image}`) : '',
    },
  },
  requesters: {
    component: FilterAutocomplete,
    optionLabel: 'fullName',
    selectedItemNotBadge: true,
    placeholder: 'Enter contact name or email',
    placeholderLg: true,
    optionItem: {
      component: FilterOptionWithAvatar,
      image: (val: any) => val?.avatar,
      title: (val: any) => val?.fullName,
      subtitle: (val: any) => val?.email,
      uploadImage: true,
    },
    selectedItem: {
      component: TmRecipient,
      name: (val: any) => val?.fullName,
      image: (val: any) => val?.avatar,
      uploadImage: true,
    },
  },
  lists: {
    component: FilterAutocomplete,
    optionLabel: 'name',
    placeholder: 'Start typing list name',
    optionItem: {
      component: FilterOptionWithAvatar,
      icon: 'tmi-people',
      avatarColor: 'crimson',
      customIconSize: '16px',
      title: (val: any) => val?.name,
      subtitle: (val: any) => val?.subtitle,
    },
  },
  domains: {
    component: FilterAutocomplete,
    // optionLabel: 'name',
    placeholder: 'Enter domain or part of domain',
    optionItem: {
      icon: 'public',
      avatarColor: 'crimson',
      customIconSize: '16px',
    },
  },
  segments: {
    component: FilterAutocomplete,
    optionLabel: 'name',
    placeholder: 'Start typing segment name',
    optionItem: {
      component: FilterOptionWithAvatar,
      icon: 'pie_chart',
      avatarColor: 'mint',
      customIconSize: '16px',
      title: (val: any) => val?.name,
      subtitle: (val: any) => val?.subtitle,
    },
  },
  countries: {
    component: FieldMultipleCountries,
    optionLabel: 'name',
    placeholder: 'Start typing country name',
  },
  customRange: {
    component: Datepickers,
    textBtn(val: any) {
      const { fromDate, toDate } = val
      const beforeFromDate = toDate ? '' : 'From '
      const beforeToDate = fromDate ? '' : 'To '
      const separator = fromDate && toDate ? ' - ' : ''
      const format = 'D MMM YYYY'
      const message = fromDate || toDate
        ? beforeFromDate +
        `${fromDate ? formatDate(fromDate, format) : ''}` +
        separator +
        beforeToDate +
        `${toDate ? formatDate(toDate, format) : ''}`
        : ''

      return message
    },
  },
  timeFromNow: {
    component: NumbersAndTypeOfDate,
    textBtn(val: any) {
      const { fromDate, toDate, dateType } = val
      if (fromDate && toDate) return `${fromDate} to ${toDate} ${dateType} from now`
      else if (fromDate) {
        return fromDate < 0
          ? `Within the last ${fromDate * -1} ${dateType}`
          : `From ${fromDate} ${dateType} from now`
      } else if (toDate) {
        return toDate < 0
          ? `More than ${toDate * -1} ${dateType} ago`
          : `Up to ${toDate} ${dateType} from now`
      } else return ''
    },
  },
  overdueMoreThan: {
    component: NumberAndTypeOfDate,
    textBtn(val: any) {
      const { fromDate, dateType } = val
      if (fromDate) return `More than ${fromDate} ${dateType} ago`
      else return ''
    },
  },
  dueInNext: {
    component: NumberAndTypeOfDate,
    textBtn(val: any) {
      const { fromDate, dateType } = val
      if (fromDate) return `Due in next ${fromDate} ${dateType}`
      else return ''
    },
  },
  amount: {
    component: FilterAmountField,
    textBtn(val: any) {
      const { from, to } = val
      if (from) return `From $${from} to ${to}`
      else return ''
    },
  },
}
