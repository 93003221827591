
import { defineComponent } from 'vue'
import TmAutoSizePanelItemDropdown from '@/components/shared/autoSize/panel/TmAutoSizePanelItemDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'TmDropdownDownload',
  components: {
    TmDropdownItem,
    TmAutoSizePanelItemDropdown,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
      default: 'Add contacts',
    },
  },
  setup() {
    const { openModal } = useModals()
    const { openModalNewContact } = useContactsOpenModal()

    return {
      openModalNewContact,
      openModal,
    }
  },
})
