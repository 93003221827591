import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eab3d42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "download-dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_auto_size_panel_item_dropdown = _resolveComponent("tm-auto-size-panel-item-dropdown")!
  const _component_top_filter_button = _resolveComponent("top-filter-button")!

  return (!_ctx.isSmMax)
    ? (_openBlock(), _createBlock(_component_tm_auto_size_panel_item_dropdown, {
        key: 0,
        text: _ctx.label,
        icon: "download",
        "icon-size": "small",
        disabled: _ctx.disabled,
        size: _ctx.size
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "menu", {}, () => [
              _createVNode(_component_tm_dropdown_item, {
                label: "Comma-separated values (.csv)",
                onClick: _ctx.openDownloadToEmailModal
              }, null, 8, ["onClick"]),
              _createVNode(_component_tm_dropdown_item, {
                label: "Microsoft Excel workbook (.xlsx)",
                onClick: _ctx.openDownloadToEmailModal
              }, null, 8, ["onClick"]),
              _renderSlot(_ctx.$slots, "default-menu-append", {}, undefined, true)
            ], true)
          ])
        ]),
        _: 3
      }, 8, ["text", "disabled", "size"]))
    : (_openBlock(), _createBlock(_component_top_filter_button, {
        key: 1,
        icon: "download",
        "icon-only": _ctx.iconOnly,
        text: _ctx.label,
        onClick: _ctx.openDownloadModal
      }, null, 8, ["icon-only", "text", "onClick"]))
}