
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAutoSizePanelItem from '@/components/shared/autoSize/panel/TmAutoSizePanelItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { TopFilterDropdownMenuItem } from '@/definitions/_layout/topFilter/types'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import type { SizeProp, IconSize, ButtonSize } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TmAutoSizePanelItem,
    TmDropdown,
  },
  props: {
    icon: {
      type: String,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
    },
    disabled: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    items: {
      type: Object as PropType<Record<string, TopFilterDropdownMenuItem>>,
      default: () => ({}),
    },
    size: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
  },
  emits: ['item-click'],
  setup(props, context) {
    const onClick = (key: string) => context.emit('item-click', key)

    return {
      onClick,
    }
  },
})
