
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import Highlight from '@/components/shared/Highlight.vue'
import ContactsListsDetailsDropdown from '@/components/pages/contacts/lists/details/ContactsListsDetailsDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'
import { useRoute } from 'vue-router'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  name: 'ContactsListsDetailsTable',
  components: {
    TmTable,
    TmPerson,
    TmStatus,
    TmSkeleton,
    TmCountry,
    TmAvatar,
    TmEllipsis,
    Highlight,
    ContactsListsDetailsDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ContactListsDetails[]>,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup() {
    const route = useRoute()
    const isShared = computed(() => route.params.shared === 'true')
    const { isLoadingMode } = useModes()
    const randomPic = () => {
      return Math.floor(1 + Math.random() * 6)
    }

    return {
      isShared,
      isLoadingMode,
      randomPic,
    }
  },
})
