import {
  teamsOptions,
  contactsStatusOptions,
  assigneeOptions,
  countryOptions,
  tagsOptions,
  contactListsOptions,
  ticketsStatusOptions, organizationTypesOptions
} from '@/definitions/_general/_data/optionsList'

export const teamsField = {
  uid: 1,
  text: 'Teams',
  icon: 'tmi-form-textbox',
  type: 'selectTeams',
  value: [],
  placeholder: 'Select teams',
  additionalOptions: {
    value: {
      text: 'Add to existing',
      hideOptions: false,
    },
    options: [
      {
        text: 'Add to existing',
        hideOptions: false,
      },
      {
        text: 'Replace all with',
        hideOptions: false,
      },
      {
        text: 'Remove all',
        hideOptions: true,
      },
      {
        text: 'Remove these',
        hideOptions: false,
      },
    ],
  },
  options: teamsOptions,
}

export const contactsStatusField = {
  uid: 2,
  text: 'Status',
  icon: 'tmi-form',
  type: 'selectStatus',
  placeholder: 'Select status',
  options: contactsStatusOptions,
}

export const assigneeField = {
  uid: 3,
  text: 'Assignee',
  icon: 'account_circle',
  type: 'assignee',
  placeholder: 'Select assignee',
  value: null,
  options: assigneeOptions,
}

export const countryField = {
  uid: 4,
  text: 'Country',
  icon: 'tmi-dropdown-form',
  type: 'selectCountry',
  placeholder: 'Select country',
  options: countryOptions,
}

export const tagsField = {
  uid: 5,
  text: 'Tags',
  icon: 'tmi-form-textbox',
  type: 'tags',
  value: [],
  placeholder: 'Enter tag name',
  additionalOptions: {
    value: {
      text: 'Add to existing',
      hideOptions: false,
    },
    options: [
      {
        text: 'Add to existing',
        hideOptions: false,
      },
      {
        text: 'Replace all with',
        hideOptions: false,
      },
      {
        text: 'Remove all',
        hideOptions: true,
      },
      {
        text: 'Remove these',
        hideOptions: false,
      },
    ],
  },
  options: tagsOptions,
}

export const listsField = {
  uid: 6,
  text: 'Lists',
  icon: 'tmi-form-textbox',
  type: 'tagsList',
  value: [contactListsOptions[0]],
  placeholder: 'Select lists',
  additionalOptions: {
    value: {
      text: 'Add to existing',
      hideOptions: false,
    },
    options: [
      {
        text: 'Add to existing',
        hideOptions: false,
      },
      {
        text: 'Replace all with',
        hideOptions: false,
      },
      {
        text: 'Remove all',
        hideOptions: true,
      },
      {
        text: 'Remove these',
        hideOptions: false,
      },
    ],
  },
  options: contactListsOptions,
}

export const ticketsStatusField = {
  uid: 7,
  text: 'Status',
  icon: 'tmi-form',
  type: 'selectStatus',
  placeholder: 'Select status',
  options: ticketsStatusOptions,
}

export const organizationTypeField = {
  uid: 8,
  text: 'Organization type',
  icon: 'tmi-dropdown-form',
  type: 'selectStatus',
  placeholder: 'Select organization type',
  options: organizationTypesOptions,
}

export const ogranizationOwnerField = {
  uid: 9,
  text: 'Organization owner',
  icon: 'account_circle',
  type: 'assignee',
  placeholder: 'Select organization owner',
  value: null,
  options: assigneeOptions,
}
