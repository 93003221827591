import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_option_with_avatar = _resolveComponent("filter-option-with-avatar")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioOptions, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.radioValue
      }, [
        _createVNode(_component_tm_field, {
          "model-value": _ctx.computedValue,
          val: item.radioValue,
          class: "mb-2",
          type: "radio",
          "align-center": item.type === 'user',
          onClick: ($event: any) => (_ctx.updateRadioValue(item.radioValue, item?.modelValue))
        }, {
          default: _withCtx(() => [
            (item.type === 'user')
              ? (_openBlock(), _createBlock(_component_filter_option_with_avatar, {
                  key: 0,
                  icon: item.icon,
                  "avatar-color": item.avatarColor,
                  title: item.fullName,
                  subtitle: item.email,
                  "custom-icon": item.icon,
                  "custom-icon-size": item.customIconSize,
                  image: item.avatar,
                  inline: ""
                }, null, 8, ["icon", "avatar-color", "title", "subtitle", "custom-icon", "custom-icon-size", "image"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ], 64))
          ]),
          _: 2
        }, 1032, ["model-value", "val", "align-center", "onClick"]),
        (item.radioValue === _ctx.computedValue)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.computedComponent), _mergeProps({
              key: 0,
              ref_for: true,
              ref: "filterEl",
              "model-value": _ctx.modelValue,
              options: _ctx.options,
              class: ["mb-2 ml-6", {
          'not-empty': _ctx.modelValue?.length,
          'autocomplete-placeholder-lg': _ctx.filterInputLib[item.field]?.placeholderLg
        }]
            }, _ctx.filterInputLib[item.field], {
              "hide-state-btns": "",
              "selected-item-icon": _ctx.selectedItemIcon,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event)))
            }), {
              option: _withCtx(({ opt }) => [
                _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ opt })), undefined, true)
              ]),
              "selected-item": _withCtx(({ opt, index, removeItem }) => [
                _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ opt, index, removeItem })), undefined, true)
              ]),
              _: 2
            }, 1040, ["model-value", "options", "class", "selected-item-icon"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}