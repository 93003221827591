
import type { PropType } from 'vue'
import { defineComponent, reactive, watch } from 'vue'

type DatePickersType = {
  fromDate?: Date | null;
  toDate?: Date | null;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<DatePickersType>,
      default: () => {},
    },
    name: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const date = reactive<DatePickersType>({
      fromDate: props.modelValue?.fromDate || null,
      toDate: props.modelValue?.toDate || null,
    })

    watch(date, () => {
      emit('update:modelValue', date.fromDate || date.toDate ? date : null)
    })

    return {
      date,
    }
  },
})
