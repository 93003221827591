
import { defineComponent } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  name: 'FilterStatuses',
  components: { FilterCheckbox, TmStatus },
  props: {
    outlineOptions: {
      type: Boolean,
    },
    hideSearch: {
      type: Boolean,
    },
  },
})
