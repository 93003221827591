import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b40018e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-recipient" }
const _hoisted_2 = { class: "tm-recipient__name" }
const _hoisted_3 = {
  key: 0,
  class: "tm-recipient__phone"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_avatar, {
      color: _ctx.totalAvatarColor,
      name: _ctx.name,
      url: _ctx.avatar,
      size: "small"
    }, null, 8, ["color", "name", "url"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
    (_ctx.phone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.phone), 1))
      : _createCommentVNode("", true),
    (_ctx.clearable)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 1,
          name: "close",
          class: "tm-recipient__close",
          size: "xSmall",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove')))
        }))
      : _createCommentVNode("", true)
  ]))
}