
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import FilterOptionWithAvatar from '@/components/shared/filters/radio/FilterOptionWithAvatar.vue'
import type { PropType } from 'vue'
import type { FilterRadioOption } from '@/definitions/shared/filters/types'
import { filterInputLib } from '@/definitions/shared/filters/filterComponentsLib/data'

export default defineComponent({
  name: 'FilterRadio',
  components: {
    FilterOptionWithAvatar,
  },
  props: {
    modelValue: {
      type: [Array, String, Object],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    radioOptions: {
      type: Array as PropType<FilterRadioOption[]>,
      required: true,
    },
    radioValue: {
      type: String,
    },
    selectedItemIcon: {
      type: String,
    },
  },
  emits: ['update:radio-value', 'update:model-value'],
  setup(props, { emit }) {
    const filterEl = ref()
    const computedValue = computed(() => props.radioValue || props.radioOptions[0].radioValue)
    const computedComponent = computed(() => {
      const val = props.radioOptions.find(item => item.radioValue === computedValue.value)?.field
      return val ? filterInputLib[val]?.component : null
    })
    const updateRadioValue = (value: string, modelValueType?: any) => {
      emit('update:radio-value', { value, modelValueType })
    }

    const onUpdateRadioValue = async () => {
      await nextTick(() => {
        filterEl.value?.$el?.querySelector('.q-placeholder')?.focus()
      })
    }

    onMounted(async () => {
      updateRadioValue(computedValue.value, props.modelValue || props.radioOptions[0].modelValue)
      await onUpdateRadioValue()
    })

    watch(() => props.radioValue, async () => {
      await onUpdateRadioValue()
    })

    return {
      filterEl,
      computedValue,
      updateRadioValue,
      filterInputLib,
      computedComponent,
    }
  },
})
