
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import TmDropdownContact from '@/components/shared/dropdowns/TmDropdownContact.vue'
import DropdownFilterStatuses from '@/components/shared/filters/dropdown/DropdownFilterStatuses.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import FilterAdd from '@/components/shared/filters/FilterAdd.vue'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'
import { useModals } from '@/compositions/modals'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'
import { countryField, assigneeField, contactsStatusField, tagsField, listsField } from '@/definitions/_general/_data/bulkFields'
import type { FiltersListType } from '@/definitions/shared/filters/types'

export default defineComponent({
  name: 'ContactsListsDetailsFilter',
  components: {
    TopFilterButton,
    TopFilter,
    TmDropdownDownload,
    TmDropdownContact,
    DropdownFilterStatuses,
    DropdownFilterRadio,
    DropdownFilterAssignee,
    FilterAdd,
  },
  props: {
    selected: {
      type: Array as PropType<ContactListsDetails[]>,
      required: true,
    },
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
  emits: ['update:radio-value', 'update:model-value', 'reset'],
  setup(props) {
    const { openModal } = useModals()
    const { openDeleteContactsModal, openModalEditContact, openMoveContactsModal } = useContactsOpenModal()
    const { openRemoveContactsFromListModal, openEditListsModal, openDeleteListModal } = useContactsListsOpenModal()

    const fieldsList = ref([
      contactsStatusField,
      assigneeField,
      countryField,
      tagsField,
      listsField,
    ])
    const clickEdit = () => {
      if (props.selected && props.selected.length > 1) {
        openModal('bulkEdit', {
          fields: fieldsList,
          selected: props.selected,
        })
      } else {
        openModalEditContact()
      }
    }

    const tagsFieldsToEdit = ref([
      tagsField,
    ])
    const openEditTagsModal = () => {
      openModal('bulkEdit', {
        selected: props.selected,
        itemName: 'contact',
        initialFields: tagsFieldsToEdit.value,
        fields: fieldsList.value,
      })
    }
    const openNoteModal = () => {
      openModal('note', {
        modalTitle: 'Add contact note',
        btnText: `Add note ${(props.selected && props.selected.length > 1) ? 'to ' + props.selected.length + ' contacts' : ''}`,
      })
    }

    return {
      openModal,
      openDeleteContactsModal,
      openDeleteListModal,
      openEditListsModal,
      openRemoveContactsFromListModal,
      clickEdit,
      openEditTagsModal,
      openNoteModal,
      openMoveContactsModal,
    }
  },
})
