import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_filter_footer = _resolveComponent("filter-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_field, {
      ref: "fieldAutocompleteEl",
      modelValue: _ctx.tempModelValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tempModelValue) = $event)),
        _ctx.onInput
      ],
      "model-value": _ctx.tempModelValue,
      type: "autocompleteDropdown",
      options: _ctx.options,
      placeholder: _ctx.placeholder,
      "item-value": _ctx.optionLabel,
      "option-search": _ctx.optionLabel ? _ctx.optionLabel : undefined,
      "selected-item-icon": _ctx.selectedItemIcon,
      "no-results-text": _ctx.noResultsText,
      class: "filter-autocomplete",
      "selected-item-not-badge": _ctx.selectedItemNotBadge
    }, {
      option: _withCtx(({ opt }) => [
        _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ opt })), undefined, true)
      ]),
      "selected-item": _withCtx(({ opt, index, removeItem }) => [
        _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ opt, index, removeItem })), undefined, true)
      ]),
      _: 3
    }, 8, ["modelValue", "model-value", "options", "placeholder", "item-value", "option-search", "selected-item-icon", "no-results-text", "selected-item-not-badge", "onUpdate:modelValue"]),
    (!_ctx.hideStateBtns)
      ? (_openBlock(), _createBlock(_component_filter_footer, {
          key: 0,
          class: "mt-4",
          onApply: _ctx.onApply,
          onCancel: _ctx.onCancel
        }, null, 8, ["onApply", "onCancel"]))
      : _createCommentVNode("", true)
  ]))
}