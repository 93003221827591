import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50aef48a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datepickers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_field, {
      modelValue: _ctx.date.fromDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date.fromDate) = $event)),
      type: "datepicker",
      placeholder: "From",
      clearable: ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_tm_field, {
      modelValue: _ctx.date.toDate,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date.toDate) = $event)),
      type: "datepicker",
      placeholder: "To",
      clearable: ""
    }, null, 8, ["modelValue"])
  ]))
}