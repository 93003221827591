import { computed, ref } from 'vue'
import { createDefaultFilters, isFiltersDefault, resetFilters } from '@/services/filters'

export const useFilters = (options: {[key: string]: any}): any => {
  const filters = ref(options)
  const defaultFilters = createDefaultFilters(filters.value)
  const isDefault = computed(() => {
    return isFiltersDefault(filters.value, defaultFilters)
  })
  const reset = () => {
    resetFilters(filters.value, defaultFilters)
  }

  return {
    filters,
    defaultFilters,
    isDefault,
    reset,
  }
}
