export const avatarColorVariant = [
  'blue',
  'blue-200',
  'blue-400',
  'blue-500',
  'orange',
  'green',
  'red',
  'mint',
  'purple',
  'crimson',
  'brown',
  'white',
  'gray',
  'light-gray',
] as const
