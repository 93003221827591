import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_tm_auto_size_panel_item = _resolveComponent("tm-auto-size-panel-item")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_panel_item, null, {
    "dropdown-item": _withCtx(() => [
      _createVNode(_component_tm_dropdown, {
        fit: "",
        position: "right-bottom",
        class: "d-block"
      }, {
        menu: _withCtx(() => [
          _renderSlot(_ctx.$slots, "menu-prepend"),
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
              return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                key: key,
                icon: item.icon,
                label: item.label,
                onClick: ($event: any) => (_ctx.onClick(key))
              }, null, 8, ["icon", "label", "onClick"]))
            }), 128))
          ]),
          _renderSlot(_ctx.$slots, "menu-append")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_tm_dropdown_item, {
            icon: _ctx.icon,
            label: _ctx.text
          }, null, 8, ["icon", "label"])
        ]),
        _: 3
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_dropdown, null, {
        menu: _withCtx(() => [
          _renderSlot(_ctx.$slots, "menu-prepend"),
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
              return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                key: key,
                icon: item.icon,
                label: item.label,
                onClick: ($event: any) => (_ctx.onClick(key))
              }, null, 8, ["icon", "label", "onClick"]))
            }), 128))
          ]),
          _renderSlot(_ctx.$slots, "menu-append")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_tm_button, {
            disable: _ctx.disabled,
            size: _ctx.size
          }, {
            default: _withCtx(() => [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 0,
                    name: _ctx.icon,
                    size: _ctx.iconSize,
                    left: ""
                  }, null, 8, ["name", "size"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }, 8, ["disable", "size"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}