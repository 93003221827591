
import { defineComponent, onMounted, ref, watch } from 'vue'
import FilterFooter from '@/components/shared/filters/FilterFooter.vue'

export default defineComponent({
  name: 'FilterAutocomplete',
  components: {
    FilterFooter,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
    },
    selectedItemIcon: {
      type: String,
    },
    hideStateBtns: {
      type: Boolean,
    },
    noResultsText: {
      type: String,
    },
    selectedItemNotBadge: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'onApply', 'onCancel'],
  setup(props, context) {
    const tempModelValue = ref(props.modelValue)

    const onInput = (val: any) => {
      tempModelValue.value = val
      if (props.hideStateBtns) {
        context.emit('update:modelValue', tempModelValue.value)
      }
    }
    const onApply = () => {
      context.emit('update:modelValue', tempModelValue.value)
      context.emit('onApply')
    }
    const onCancel = () => {
      tempModelValue.value = props.modelValue
      context.emit('onCancel')
    }

    const fieldAutocompleteEl = ref()
    const focus = () => {
      fieldAutocompleteEl.value.focus()
    }

    onMounted(() => {
      focus()
    })

    watch(
      () => props.modelValue,
      () => {
        tempModelValue.value = props.modelValue
      }
    )

    return {
      tempModelValue,
      onInput,
      onApply,
      onCancel,
      fieldAutocompleteEl,
      focus,
    }
  },
})
