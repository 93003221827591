
import { computed, defineComponent } from 'vue'
import type { PropType } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { AvatarColorVariant } from '@/definitions/shared/types'
import { randomIntFromInterval } from '@/services/utils'
import { avatarColorVariant } from '@/definitions/_general/_data/avatarColorVariant'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    name: {
      type: String,
    },
    phone: {
      type: String,
    },
    avatarColor: {
      type: String as PropType<AvatarColorVariant>,
    },
    avatar: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
  },
  emits: ['remove'],
  setup(props) {
    const totalAvatarColor = computed(() => {
      return props.avatarColor || avatarColorVariant[randomIntFromInterval(0, avatarColorVariant.length)]
    })

    return {
      totalAvatarColor,
    }
  },
})
