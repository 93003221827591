
import { defineComponent } from 'vue'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import FilterStatuses from '@/components/shared/filters/FilterStatuses.vue'

export default defineComponent({
  name: 'DropdownFilterStatuses',
  components: { FilterStatuses, DropdownFilterCheckbox },
  props: {
    name: {
      type: String,
      default: 'Status',
    },
    hideSearch: {
      type: Boolean,
    },
  },
})
