
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'ContactsListsDetailsEmpty',
  components: {
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { openModal } = useModals()

    const openContactsListsAddModal = () => openModal('grayTileButtons', {
      modalTitle: 'Add contacts',
      items: [
        {
          icon: 'tmi-person-add',
          label: 'Create a new contact',
          description: 'Add contacts one by one',
          onClick: () => openModal('contact'),
        },
        {
          icon: 'tmi-people',
          label: 'Add from existing contacts',
          description: 'Copy contacts from other Lists',
          onClick: () => openModal('addExistingContacts'),
        },
      ],
    })

    return { openContactsListsAddModal }
  },
})
