import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2af8507d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-option__content" }
const _hoisted_2 = { class: "filter-option__title" }
const _hoisted_3 = {
  key: 0,
  class: "filter-option__subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-option", {
      'filter-option--inline': _ctx.inline
    }])
  }, [
    _createVNode(_component_tm_avatar, {
      size: "small",
      "placeholder-custom-size": "16px",
      "custom-size": "32px",
      "custom-icon": _ctx.icon,
      "custom-icon-class": "ma-0",
      "custom-icon-size": _ctx.customIconSize,
      url: _ctx.image,
      color: _ctx.totalAvatarColor,
      name: _ctx.title,
      "placeholder-type": _ctx.icon ? 'icon' : 'symbol'
    }, null, 8, ["custom-icon", "custom-icon-size", "url", "color", "name", "placeholder-type"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}