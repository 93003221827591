import { useModals } from '@/compositions/modals'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'

type UseContactsListsOpenModal = () => {
  openListsShareModal: (plural: boolean) => void
  openRemoveContactFromListModal: (contactName: string) => void
  openRemoveContactsFromListModal: (selected: ContactListsDetails[]) => void
  openListsModal: () => void
  openEditListsModal: (listName: string, tags: any[]) => void
  openDuplicateListsModal: (listName: string, tags: any[]) => void
  openDeleteListModal: (listName: string) => void
  openDeleteListsModal: (selected: any[]) => void
}

const useContactsListsOpenModal: UseContactsListsOpenModal = () => {
  const { openModal } = useModals()

  const openListsShareModal = (plural: boolean) => {
    openModal('confirmation', {
      title: 'Share with sub-accounts',
      text: 'After sharing the selected lists, your sub-accounts will be able to view, edit and delete the contacts in these lists. Are you sure you want to continue?',
      btnText: `Share list${plural ? 's' : ''}`,
    })
  }

  const openRemoveContactFromListModal = (contactName: string) => {
    openModal('confirmation', {
      title: 'Remove from list',
      text: [
        {
          text: 'Are you sure you would like to remove ',
        },
        {
          text: contactName,
          style: 'semi-bold',
        },
        {
          text: ' from this list? This action cannot be undone.',
        },
      ],
      btnText: 'Remove',
      btnColor: 'error',
    })
  }

  const openRemoveContactsFromListModal = (selected: ContactListsDetails[]) => {
    if (selected.length > 1) {
      openModal('confirmation', {
        title: 'Remove from list',
        text: [
          {
            text: 'Are you sure you would like to remove ',
          },
          {
            text: selected.length + ' contacts',
            style: 'semi-bold',
          },
          {
            text: ' from this list? This action cannot be undone.',
          },
        ],
        btnText: 'Remove',
        btnColor: 'error',
      })
    } else {
      openRemoveContactFromListModal(selected[0].person.fullName)
    }
  }

  const openListsModal = () => {
    openModal('contactsLists')
  }
  const openEditListsModal = (listName: string, tags: any[]) => {
    openModal('contactsLists', {
      btnText: 'Save',
      modalTitle: 'Edit list',
      name: listName,
      tags: tags,
    })
  }
  const openDuplicateListsModal = (listName: string, tags: any[]) => {
    openModal('contactsLists', {
      btnText: 'Duplicate',
      modalTitle: 'Duplicate list',
      label: 'New list name',
      name: `${listName} (1)`,
      tags: tags,
      hideText: true,
    })
  }

  const openDeleteListModal = (listName: string) => {
    openModal('contactsGroupDelete', {
      title: 'Delete list',
      text: [
        'By confirming this action, ',
        { text: listName, style: 'semi-bold' },
        ' list with be deleted. This action cannot be undone. Are you sure you want to delete it? \n\nPlease select what should happen to the contacts in this list after it is deleted.',
      ],
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openDeleteListsModal = (selected: any[]) => {
    if (selected.length > 1) {
      openModal('contactsGroupDelete', {
        title: 'Delete lists',
        text: [
          'By confirming this action, ',
          { text: selected.length + ' lists', style: 'semi-bold' },
          ' will be deleted. This action cannot be undone. Are you sure you want to delete them? \n\nPlease select what should happen to the contacts in these ',
          { text: selected.length + ' lists', style: 'semi-bold' },
          ' after they are deleted.',
        ],
        btnText: 'Delete ' + selected.length + ' lists',
        btnColor: 'red',
      })
    } else {
      openDeleteListModal(selected[0].listName)
    }
  }

  return {
    openListsShareModal,
    openRemoveContactFromListModal,
    openRemoveContactsFromListModal,
    openListsModal,
    openEditListsModal,
    openDuplicateListsModal,
    openDeleteListModal,
    openDeleteListsModal,
  }
}

export default useContactsListsOpenModal
